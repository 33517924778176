import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Home from "./pages/Home";
import VerifyAccount from "./pages/VerifyAccount";
import { AuthProvider } from "./context/AuthContext";
import { LicenseInfo } from "@mui/x-license-pro";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ReactFlowProvider } from "reactflow";
import Test from "./pages/Test";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { mainnet, } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import Login from "./pages/Login"
import Registration from "./pages/Registration"
import { PrivateRoute } from "./components/PrivateRoute";

const config = getDefaultConfig({
  appName: "My RainbowKit App",
  projectId: "adc1ef60b8e47ee0ca7283d342590634",
  chains: [mainnet,],
  ssr: false, // If your dApp uses server side rendering (SSR)
});

const queryClient = new QueryClient();

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const theme = createTheme({
  typography: {
    fontFamily: "Sora, sans-serif",
  },
  palette: {
    text: {
      primary: "#ffffff", // White text for primary text
      secondary: "#cccccc", // Lighter white (greyish) for secondary text, if needed
    },
    action: {
      active: "#ffffff",
      hover: "rgba(255, 255, 255, 0.2)",
      selected: "#ffffff",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute><Home /></PrivateRoute>,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/registration",
    element: <Registration />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <ReactFlowProvider>
              <RouterProvider router={router} />
            </ReactFlowProvider>
          </ThemeProvider>
        </AuthProvider>
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
);
