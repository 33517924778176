import React from "react";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import "../css/navbar.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useCallback, useState } from "react";
import Logo from "../assets/logo.png";
import Icon from "../assets/icon.png";

function NavbarCustom() {
  const { authToken, logout } = useAuth();
  const [expanded, setExpanded] = useState(false);
  const handleNavbarToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Navbar
      variant="dark"
      expand="md"
      expanded={expanded}
      className={expanded ? "blurred-background" : ""}
      style={{
        backgroundColor: "transparent",
        border: "1px solid white",
        margin: "20px 40px",
      }}
    >
      <Container fluid className="p-0">
        <Navbar.Brand style={{ width: "200px" }} >
          <img style={{ width: "15%", marginRight: 15 }} src={Icon} />
          <img style={{ width: "50%" }} src={Logo} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link as={Link} to="/" className="nav-link">
              Home
            </Nav.Link> */}
          </Nav>
          <Nav>
            <Button
              variant="outline-danger"
              style={{ marginRight: 12 }}
              onClick={logout}
            >
              Get Credits
            </Button>
            {authToken ? (
              <Button
                variant="outline-danger"
                style={{ marginRight: 12 }}
                onClick={logout}
              >
                Logout
              </Button>
            ) : (
              <></>
            )}
            <ConnectButton />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
