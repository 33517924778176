import React, { useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';

const RegistrationCard = () => {
  const [formData, setFormData] = useState({
    email: '',
    password1: '',
    password2: '',
  });
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('info');

  const { register } = useAuth();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessageType('info');
    setMessage('Registering...');
    try {
      await register(formData.email, formData.password1, formData.password2);
      setMessageType('success');
      setMessage('Registration successful. Please check your email to verify.');
    } catch (error) {
      setMessageType('danger');
      setMessage('Registration error: ' + (error.response && error.response.data ? JSON.stringify(error.response.data) : error.message));
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '88vh' }}>
      <Card bg="dark" text="white" style={{ width: '24rem' }}>
        <Card.Body>
          <Card.Title className="text-center">Register Your Account</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password1"
                placeholder="Password"
                value={formData.password1}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formConfirmPassword" className="mt-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="password2"
                placeholder="Confirm Password"
                value={formData.password2}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-4 w-100">
              Register
            </Button>
            {message && <Alert variant={messageType} className="mt-3">{message}</Alert>}
          </Form>
          <div className="mt-4 text-center">
            Already have an account? <Link to="/login" className="text-light">Log in</Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default RegistrationCard;
