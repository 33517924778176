import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Layout from "../components/Layout";
import RegistrationCard from "../components/RegistrationCard";
import LoginCard from "../components/LoginCard";
import VerifyNonce from "../components/VerifyNonce";
import UserInfo from "../components/UserInfo";

function Home() {
  return (
    <Layout>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} md={7}>
          <UserInfo />
        </Col>
      </Row>
    </Layout>
  );
}

export default Home;
