import React, { useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';


const LoginCard = () => {
  const navigate = useNavigate(); 
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const [loginMessage, setLoginMessage] = useState('');
  const [messageType, setMessageType] = useState('info');

  const { login } = useAuth();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setMessageType('info');
    setLoginMessage("Logging in...");
    try {
      let res = await login(loginData.email, loginData.password);
      console.log(res)
      setMessageType('success');
      setLoginMessage('Login successful.');
      setTimeout(() => navigate('/'), 2000);
    } catch (error) {
      setMessageType('danger');
      setLoginMessage('Login error: ' + (error.response && error.response.data ? JSON.stringify(error.response.data) : error.message));
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '88vh' }}>
      <Card bg="dark" text="white" style={{ width: '24rem' }}> {/* Adjust the width as needed */}
        <Card.Body>
          <Card.Title className="text-center">Log In</Card.Title>
          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={loginData.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                value={loginData.password}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-4 w-100">
              Login
            </Button>
            {loginMessage && <Alert variant={messageType} className="mt-3">{loginMessage}</Alert>}
          </Form>
          <div className="mt-4 text-center">
            Don't have an account? <Link to="/registration" className="text-light">Register</Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default LoginCard;
