import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { useAuth } from "../context/AuthContext"; // Adjust the path as necessary

function VerifyAccount() {
  const { token } = useParams();
  const { verifyEmail } = useAuth(); // Use the verifyEmail function from context
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verify = async () => {
      try {
        setMessage("Verifying email...");
        const detail = await verifyEmail(token);
        setMessage(detail);
      } catch (e) {
        console.error(e);
        setMessage("Verification failed. Please try again or contact support.");
      }
    };

    verify();
  }, [token, verifyEmail]);

  return (
    <Layout>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Registration Verification</Card.Title>
              <Card.Body>{message}</Card.Body>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default VerifyAccount;
