// ToastNotification.js
import React from 'react';
import { Toast } from 'react-bootstrap';

const ToastNotification = ({ show, message, onClose, success = true }) => {
  return (
    <Toast
      onClose={onClose}
      show={show}
      delay={3000}
      autohide
      style={{
        position: 'fixed',
        top: 20,
        right: 20,
        minWidth: '200px',
        zIndex: 1000,
        backgroundColor: success ? '#d4edda' : '#f8d7da',
        color: success ? '#155724' : '#721c24',
      }}
    >
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

export default ToastNotification;
