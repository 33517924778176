import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Layout from "../components/Layout";
import RegistrationCard from "../components/RegistrationCard";
import LoginCard from "../components/LoginCard";
import VerifyNonce from "../components/VerifyNonce";

function Registration() {
  return (
    <Layout>
      <Row>
        <Col xs={12}>
          <RegistrationCard />
        </Col>
      </Row>
    </Layout>
  );
}

export default Registration;
