import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Layout from "../components/Layout";
import RegistrationCard from "../components/RegistrationCard";
import LoginCard from "../components/LoginCard";
import VerifyNonce from "../components/VerifyNonce";

function Login() {
  return (
    <Layout>
      <Row className="d-flex justify-content-center align-items-center" style={{minHeight: '88vh'}}>
        <Col xs={12} md={4} className="justify-content-center">
          <LoginCard />
        </Col>
      </Row>
    </Layout>
  );
}

export default Login;
