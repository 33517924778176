import React, { useState, useEffect } from "react";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import { useSignMessage } from "wagmi";
import ToastNotification from "./ToastNotification";

const UserInfo = () => {
  const { getUserInfo, authAxios, signNonce } = useAuth();
  const { data: signMessageData, signMessage } = useSignMessage();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [userInfo, setUserInfo] = useState({
    git_username: "",
    ethereum_address: "",
    ethereum_address_verified: false,
    email: "",
    nonce: "",
    subscription_expiration_date: "N/A",
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await getUserInfo();
        console.log(response)
        if (response) {
          // Ensure response fields fallback to initial values if undefined
          const updatedUserInfo = { ...userInfo };
          Object.keys(userInfo).forEach((key) => {
            updatedUserInfo[key] = response[key] ?? userInfo[key];
          });
          setUserInfo(updatedUserInfo);
        }
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleSignNonce = async () => {
    await signNonce(userInfo.nonce, signMessageData);
  };

  useEffect(() => {
    if (signMessageData) {
      console.log("Nonce signed:", signMessageData);
      handleSignNonce();
    }
  }, [signMessageData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (["git_username"].includes(name)) {
      // Only allowing github_username to be editable
      setUserInfo({ ...userInfo, [name]: value });
    }
  };

  const handleUpdateUserInfo = async (e) => {
    e.preventDefault();
    // Assuming you have an endpoint to handle user info updates.
    // Adjust the URL as needed.
    try {
      await authAxios.put(`${process.env.REACT_APP_API_URL}/users/me/update/`, {
        git_username: userInfo.git_username,
      });
      console.log("Update successful");
      setToastMessage("Update successful"); // Set success message
      setIsSuccess(true); // Indicate that this is a success message
      setShowToast(true); // Show the toast
    } catch (error) {
      console.error("Error updating user info", error);
      setToastMessage("Error updating user info"); // Set error message
      setIsSuccess(false); // Indicate that this is an error message
      setShowToast(true); // Show the toast
    }
  };

  const handleVerifyWallet = () => {
    console.log()
    signMessage({ message: userInfo.nonce });
  };

  return (
    <>
      <h3>Dashboard</h3>
      <Card bg="dark" text="white" className="mt-4">
        <Card.Body>
          <Card.Title className="mb-4">User Info</Card.Title>
          <Form onSubmit={handleUpdateUserInfo}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2} className="text-white">
                🐙 GitHub User
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="git_username"
                  value={userInfo.git_username}
                  onChange={handleInputChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2} className="text-white">
                🌐 Address
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="ethereum_address"
                  value={userInfo.ethereum_address}
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2} className="text-white">
                ☑️ Verified
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="ethereum_address_verified"
                  value={userInfo.ethereum_address_verified ? "✅" : "❌"}
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2} className="text-white">
                ✉️ Email
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="email"
                  value={userInfo.email}
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2} className="text-white">
                🔑 Valid until
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="subscription_expiration_date"
                  value={userInfo.subscription_expiration_date}
                  readOnly
                />
              </Col>
            </Form.Group>
          </Form>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button onClick={handleUpdateUserInfo} className="me-2" variant="primary">
                Update GitHub Username
              </Button>
              <Button onClick={handleVerifyWallet} variant="primary">
                Verify Wallet
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ToastNotification
        show={showToast}
        onClose={() => setShowToast(false)}
        message={toastMessage}
        success={isSuccess}
      />
    </>
  );
};

export default UserInfo;
